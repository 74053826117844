<template>
  <BOverlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BCard
      aria-hidden="true"
      class="mb-0"
    >
      <b-form>
        <!-- Calendario: Input Fields -->
        <BRow class="mt-1">

          <!-- Field: Codigo calendario -->
          <BCol
            cols="6"
            md="6"
          >
            <BFormGroup
              label="Código de calendario *:"
              label-for="codigo_calendario"
            >
              <!-- label-cols-md="5"
              label-class="text-right" -->
              <BFormInput
                id="codigo_calendario"
                v-model="calendario.codigo_calendario"
                placeholder="Ingrese el código"
                :state="v$.calendario.codigo_calendario.$error === true
                ? false
                : null"
                @blur.native="v$.calendario.codigo_calendario.$touch"
              />
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.calendario.codigo_calendario.$error"
                id="codigo_calendarioInfo"
                class="text-right"
              >
                <p v-for="error of v$.calendario.codigo_calendario.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- Field: Periodo Escolar -->
          <BCol
            cols="6"
            md="6"
          >
            <BFormGroup
              label="Año escolar (periodo) *"
              label-for="periodo_escolar"
            >
              <BFormSelect
                id="periodo_escolar"
                v-model="calendario.periodo_escolar"
                :options="periodosOptions"
                :state="v$.calendario.periodo_escolar.$error === true
                ? false
                : null"
                @blur.native="v$.calendario.periodo_escolar.$touch"
                @change="changePeriodo"
              />
              <!-- <BFormInput
                placeholder="Ingrese el periodo"
                @keyup='calendario.periodo_escolar = formatSoloNumeros(calendario.periodo_escolar)'
                type="number"
                :state="v$.calendario.periodo_escolar.$error === true
                ? false
                : null"
                @blur.native="v$.calendario.periodo_escolar.$touch"
              /> -->
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.calendario.periodo_escolar.$error"
                id="periodo_escolarInfo"
                class="text-right"
              >
                <p v-for="error of v$.calendario.periodo_escolar.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- Field: fecha_inicio -->
          <BCol
            cols="6"
            md="6"
          >
            <BFormGroup
              label="Fecha inicio *"
              label-for="fecha_inicio"
            >
              <!-- <BFormInput
                id="fecha_inicio"
                v-model="calendario.fecha_inicio"
                placeholder="Ingrese el fecha inicio"
                :state="v$.calendario.fecha_inicio.$error === true
                ? false
                : null"
                @blur.native="v$.calendario.fecha_inicio.$touch"
              /> -->
              <b-form-datepicker
                v-model="calendario.fecha_inicio"
                id="fecha_inicio"
                :min="minInicio"
                :max="maxInicio"
                placeholder="Seleccione una fecha"
                hide-header
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short'
                }"
                start-weekday="1"
                locale="es-CL"
                :date-disabled-fn="dateDisabled"
                label-help=""
                :state="v$.calendario.fecha_inicio.$error === true
                  ? false
                  : null"
                @blur.native="v$.calendario.fecha_inicio.$touch"
                @input="changeFechaInicio"
              />
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.calendario.fecha_inicio.$error"
                id="fecha_inicioInfo"
                class="text-right"
              >
                <p v-for="error of v$.calendario.fecha_inicio.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- Field: Fecha_termino -->
          <BCol
            cols="6"
            md="6"
          >
            <BFormGroup
              label="Fecha termino *"
              label-for="fecha_termino"
            >
              <!-- <BFormInput
                id="fecha_termino"
                v-model="calendario.fecha_termino"
                placeholder="Ingrese la fecha termino"
                :state="v$.calendario.fecha_termino.$error === true
                ? false
                : null"
                @blur.native="v$.calendario.fecha_termino.$touch"
              /> -->

              <b-form-datepicker
                v-model="calendario.fecha_termino"
                id="fecha_termino"
                placeholder="Seleccione una fecha"
                hide-header
                :min="minTermino"
                :max="maxTermino"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short'
                }"
                start-weekday="1"
                locale="es-CL"
                :date-disabled-fn="dateDisabled"
                label-help=""
                :state="v$.calendario.fecha_termino.$error === true
                  ? false
                  : null"
                @blur.native="v$.calendario.fecha_termino.$touch"
              />
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.calendario.fecha_termino.$error"
                id="abreviaturaInfo"
                class="text-right"
              >
                <p v-for="error of v$.calendario.fecha_termino.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- Field: ESTABLECIMIENTO -->
          <BCol
            v-if="$can('readEstablecimiento', 'calendarios')"
            :cols="$can('readEstablecimiento', 'calendarios')
              ? '6'
              : '12'"
            :md="$can('readEstablecimiento', 'calendarios')
              ? '6'
              : '12'"
          >
            <BFormGroup
              label="Establecimiento *"
              label-for="id_establecimiento"
            >
              <BFormSelect
                v-model="calendario.id_establecimiento"
                :options="establecimientoOption"
                :state="v$.calendario.id_establecimiento.$error === true
                ? false
                : null"
                @blur.native="v$.calendario.id_establecimiento.$touch"
                @change="cargaDirectores(calendario.id_establecimiento)"
              />
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.calendario.id_establecimiento.$error"
                id="correoInfo"
                class="text-right"
              >
                <p v-for="error of v$.calendario.id_establecimiento.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- Field: DIRECTOR -->
          <BCol
            :cols="$can('readEstablecimiento', 'calendarios')
              ? '6'
              : '12'"
            :md="$can('readEstablecimiento', 'calendarios')
              ? '6'
              : '12'"
          >
            <BFormGroup
              label="Director *"
              label-for="id_director"
            >
              <b-alert
                v-if="alertDirector"
                variant="primary"
                show
                class="text-center mt-25 mr-25 ml-25 pb-1 pt-1"
              >
                El establecimiento seleccionado no tiene directores creados.
              </b-alert>
              <BFormSelect
                id="id_director"
                v-model="calendario.id_director"
                :options="directoresOptions"
                :state="v$.calendario.id_director.$error === true
                ? false
                : null"
                :disabled="directoresOptions.length === 1"
                @blur.native="v$.calendario.id_director.$touch"
              />

              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.calendario.id_director.$error"
                id="correoInfo"
                class="text-right"
              >
                <p v-for="error of v$.calendario.id_director.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>

            </BFormGroup>
          </BCol>

        </BRow>

        <colLinea />

        <!-- Action Buttons -->
        <btn-submit
          class="float-right"
          variant="primary"
          :btnText="btnSubmit"
          :modulo="nombre_permiso"
          @processBtn="submitOption"
        />
      </b-form>
    </BCard>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BCard, BInputGroup, BInputGroupPrepend,
  BFormDatepicker, BFormSelect, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapGetters, mapActions, mapMutations } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required
  , maxLength, email, helpers } from '@vuelidate/validators'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// COMPONENTES RECICLADOS
import btnSubmit from '../../../components/Form/btnSubmit.vue'
import colLinea from '../../../components/Form/colLinea.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BCard,
    BFormDatepicker,
    BFormSelect,
    BAlert,
    vSelect,

    // COMPONENTES RECICLADOS
    btnSubmit,
    colLinea,
  },
  mixins: [formatos],
  data() {
    return {
      nombre_permiso: 'calendarios',
      cargando: true,
      alertDirector: false,

      minInicio: null,
      maxInicio: null,
      minTermino: null,
      maxTermino: null,
      periodosOptions: [
        {
          value: 2023,
          text: '2023',
        },
        {
          value: 2024,
          text: '2024',
        },
        {
          value: 2025,
          text: '2025',
        },
        {
          value: 2026,
          text: '2026',
        },
        {
          value: 2027,
          text: '2027',
        },
      ],
      directoresOptions: [{
        value: null,
        text: 'Seleccione el director',
      }],
      establecimientoOption: [],
    }
  },
  computed: {
    ...mapGetters({
      getDirectores: 'personas/getDirectores',
      getEstablecimientos: 'establecimientos/getEstablecimientos',
      user: 'auth/user',
    }),
  },
  props: {
    calendario: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Calendario',
    },
  },
  validations() {
    let establecimiento = new Object()
    if(this.$can('readEstablecimiento', 'calendarios')) {
      establecimiento = {
        $autoDirty: true,
        required: helpers.withMessage('El campo es requerido.', required),
      }
    }
    return {
      calendario: {
        codigo_calendario: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 50 caracteres.'
            , maxLength(50)),
        },
        periodo_escolar: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 4 caracteres.'
            , maxLength(4)),
        },
        fecha_inicio: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_termino: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_director: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_establecimiento: establecimiento,
      }
    }
  },
  mounted() {
    this.changePeriodo()
    this.cargaUsuario()
  },
  methods: {
    ...mapActions({
      fetchDirectores: 'personas/fetchDirectores',
      fetchEstablecimientos: 'establecimientos/fetchEstablecimientos',
      attempt: 'auth/attempt',
    }),
    cargaUsuario() {
      this.attempt().then(() => {
        if (this.user.id_rol === 1) { // SUPER ADMIN
          this.fetchEstablecimientos().then(() => {
            this.establecimientoOption = [{value: null, text: 'Seleccione el establecimiento...' }]
            this.getEstablecimientos.forEach(establecimiento => {
              this.establecimientoOption.push(
                {
                  value: establecimiento.id,
                  text: establecimiento.nombre
                }
              )
            })
          })
        } else {
          this.cargaDirectores(this.user.id_establecimiento)
        }
      })
    },
    cargaDirectores(id_establecimiento) {
      this.fetchDirectores(id_establecimiento).then(() => {
        this.directoresOptions = []

        if (this.getDirectores.length === 0) {
          this.directoresOptions = [{
            value: null,
            text: 'No hay directores disponibles',
          }]
          this.alertDirector = true
        } else {
          this.directoresOptions = [{
            value: null,
            text: 'Seleccione el director',
          }]
          this.alertDirector = false
        }

        this.getDirectores.forEach(director => {
          const text = director.nombre+' '+director.primer_apellido+' '+director.segundo_apellido
          this.directoresOptions.push({
            value: director.id,
            text,
          })
        })
      })
    },
    changePeriodo() {
      this.calendario.fecha_inicio = null
      this.calendario.fecha_termino = null
      this.minInicio = new Date(this.calendario.periodo_escolar, 1, 1)
      this.maxInicio = new Date(this.calendario.periodo_escolar, 11, 30)
    },
    changeFechaInicio() {
      let fecha_inicio = new Date(this.calendario.fecha_inicio)
      fecha_inicio.setDate(fecha_inicio.getDate() + 2);
      this.calendario.fecha_termino = null
      this.minTermino = new Date(fecha_inicio)
      this.maxTermino = new Date(this.calendario.periodo_escolar, 11, 31)
    },
    submitOption() {
      this.v$.calendario.$touch()
      if (!this.v$.calendario.$invalid) {
        this.$emit('processForm', this.calendario)
      }
    },
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.calendario.insignia = e.target.result
      }
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>
